import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  catalogData: null,
  loading: false,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
}

const catalogSlice = createSlice({
    name:"catalog",
    initialState: initialState,
    reducers: {
        setCatalogData(state, value) {
          state.catalogData = value.payload;
        },
        setLoading(state, value) {
          state.loading = value.payload;
        },
        setToken(state, value) {
          state.token = value.payload;
        },
      },
});

export const { setCatalogData, setLoading, setToken } = catalogSlice.actions;

export default catalogSlice.reducer;
