import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { createCategory } from "../../../services/operations/courseDetailsAPI"
import { setCatalogData } from "../../../slices/catalogSlice"

export default function AddCatalog() {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm()

  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)

  const [categoryData, setCategoryData] = useState({
    categoryName: "",
    categoryDescription: "",
  })

  const { categoryName, categoryDescription } = categoryData

  const handleOnChange = (e) => {
    setCategoryData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  // Handle Form Submission
  const onSubmit = async (data) => {
    const result = await createCategory(data, token)
    if (result) {
      dispatch(setCatalogData(result))
      setCategoryData({
        categoryName: "",
        categoryDescription: "",
      })
    }
    window.location.reload()
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 rounded-md border-[1px] border-richblack-700 bg-richblack-800 p-6"
      >
        <div className="flex flex-col space-y-2">
          <label className="text-sm text-richblack-5" htmlFor="categoryTitle">
            Category Title <sup className="text-pink-200">*</sup>
          </label>
          <input
            {...register("categoryName", { required: true })}
            onChange={handleOnChange}
            value={categoryName}
            id="categoryTitle"
            name="categoryName"
            placeholder="Enter Category Title"
            className="form-style w-full"
          />
          {errors.categoryTitle && (
            <span className="ml-2 text-xs tracking-wide text-pink-200">
              Category Title is required
            </span>
          )}
        </div>

        <div className="flex flex-col space-y-2">
          <label className="text-sm text-richblack-5" htmlFor="categorySortDec">
            Category Description <sup className="text-pink-200">*</sup>
          </label>
          <textarea
            {...register("categoryDescription", { required: true })}
            onChange={handleOnChange}
            value={categoryDescription}
            id="categorySortDec"
            name="categoryDescription"
            placeholder="Enter Description"
            className="form-style resize-x-none min-h-[130px] w-full"
          />
          {errors.categorySortDec && (
            <span className="ml-2 text-xs tracking-wide text-pink-200">
              Category Description is required
            </span>
          )}
        </div>

        <div className="flex justify-center gap-x-2">
          <button
            className={`flex cursor-pointer items-center gap-x-2 rounded-md bg-white px-[20px] py-[8px] font-semibold text-richblack-900`}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  )
}
